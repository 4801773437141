import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import BackToTop from "@/components/BackToTop";
import ErrorBoundary from "@/components/ErrorBoundary";
import { AuthProvider } from "@/context/AuthContext";
import { useAuth } from "@/context/useAuth";
import ProtectedRoute from "@/components/ProtectedRoute";
import { BlogProvider } from "@/context/BlogContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

// Lazy load components
const MainContent = lazy(() => import("@/components/MainContent"));
const CveSearch = lazy(() => import("@/pages/CveSearch"));
const RssFeeds = lazy(() => import("@/pages/RssFeeds"));
const Blog = lazy(() => import("@/pages/Blog"));
const WhoAmI = lazy(() => import("@/pages/WhoAmI"));
const Privacy = lazy(() => import("@/pages/Privacy"));
const BlogAdminEditor = lazy(() => import("@/pages/BlogAdminEditor"));
const AdminLogin = lazy(() => import("@/pages/AdminLogin"));
const UserAdminPage = lazy(() => import("@/pages/UserAdminPage"));
const FAQ = lazy(() => import("@/pages/FAQ"));
const Contact = lazy(() => import("@/pages/Contact"));


function App() {
  const { user, role } = useAuth(); // Use the useAuth hook

  return (
    <BlogProvider>
      <ErrorBoundary>
        <div className="App" style={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }}>
          <Header />
          <Navbar />
          <Suspense
            fallback={
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "100vh" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/rssfeed" element={<RssFeeds />} />
              <Route
                path="/cve-search"
                element={
                  <ProtectedRoute requiredRole="admin">
                    <CveSearch />
                  </ProtectedRoute>
                }
              />
              <Route path="/blog/*" element={<Blog />} />
              <Route path="/whoami" element={<WhoAmI />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/blog-admin"
                element={
                  <ProtectedRoute requiredRole="admin">
                    <BlogAdminEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-admin"
                element={
                  <ProtectedRoute requiredRole="admin">
                    <UserAdminPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<AdminLogin />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
          <Footer />
          <BackToTop />
        </div>
      </ErrorBoundary>
    </BlogProvider>
  );
}

export default App;
// src/components/BackToTop.js
import React, { useState, useEffect } from 'react';
import '@/styles/BackToTop.css';

function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Add scroll event listener to track the page offset
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', toggleVisibility);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Scroll to the top smoothly
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
      <button onClick={handleBackToTop} className="back-to-top-btn">
        Back to Top
      </button>
    )
  );
}

export default BackToTop;

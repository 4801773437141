import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/context/useAuth";
import "@/styles/Navbar.css";

function Navbar() {
  const { user, role, logout } = useAuth();
  const navbarCollapseRef = useRef(null);

  useEffect(() => {
    document.body.classList.add("js-enabled");
  }, []);

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      const navbarCollapse = navbarCollapseRef.current;
      if (navbarCollapse && navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
    }
  };

  return (
    <nav className="navbar navbar-expand-lg custom-navbar sticky-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <i className="bi bi-stars"></i> incredincomp
          <noscript><span className="fallback-icon">★</span></noscript>
        </Link>
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown" ref={navbarCollapseRef}>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={handleLinkClick}>
                <i className="bi bi-house-door-fill"></i> Home
                <noscript><span className="fallback-icon">🏠</span></noscript>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog" onClick={handleLinkClick}>
                <i className="bi bi-journal-text"></i> Blog
                <noscript><span className="fallback-icon">📖</span></noscript>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/rssfeed" onClick={handleLinkClick}>
                <i className="bi bi-rss"></i> RSS Feed
                <noscript><span className="fallback-icon">📡</span></noscript>
              </Link>
            </li>
            {role === "admin" && (
              <li className="nav-item">
                <Link className="nav-link" to="/cve-search" onClick={handleLinkClick}>
                  <i className="bi bi-search"></i> CVE Search
                  <noscript><span className="fallback-icon">🔍</span></noscript>
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link className="nav-link" to="/whoami" onClick={handleLinkClick}>
                <i className="bi bi-person-fill"></i> whoami
                <noscript><span className="fallback-icon">👤</span></noscript>
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://github.com/incredincomp"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <i className="bi bi-github"></i> GitHub
                <noscript><span className="fallback-icon">🐱</span></noscript>
              </a>
            </li>
            <li className="nav-item">
              {role === "admin" && (
                <Link className="nav-link" to="/user-admin" onClick={handleLinkClick}>
                  <i className="bi bi-people-fill"></i> User Admin
                  <noscript><span className="fallback-icon">👥</span></noscript>
                </Link>
              )}
            </li>
            <li className="nav-item">
              {user ? (
                <button className="nav-link btn btn-link" onClick={() => { logout(); handleLinkClick(); }}>
                  <i className="bi bi-box-arrow-right"></i> Logout
                  <noscript><span className="fallback-icon">🚪</span></noscript>
                </button>
              ) : (
                <Link className="nav-link" to="/login" onClick={handleLinkClick}>
                  <i className="bi bi-box-arrow-in-right"></i> Login
                  <noscript><span className="fallback-icon">🔑</span></noscript>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../components/firebase";
import { onAuthStateChanged } from "firebase/auth";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "blogPosts"));
        const posts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogPosts(posts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, []);

  const addBlogPost = async (post) => {
    if (user) {
      try {
        const docRef = await addDoc(collection(db, "blogPosts"), post);
        const newPost = { id: docRef.id, ...post };
        setBlogPosts((prevPosts) => [...prevPosts, newPost]);
      } catch (error) {
        console.error("Error adding blog post:", error);
      }
    } else {
      console.error("User must be authenticated to add a blog post.");
    }
  };

  const updateBlogPost = async (updatedPost) => {
    if (user) {
      try {
        const postRef = doc(db, "blogPosts", updatedPost.id);
        await updateDoc(postRef, updatedPost);
        setBlogPosts((prevPosts) =>
          prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post))
        );
      } catch (error) {
        console.error("Error updating blog post:", error);
      }
    } else {
      console.error("User must be authenticated to update a blog post.");
    }
  };

  return (
    <BlogContext.Provider value={{ blogPosts, setBlogPosts, addBlogPost, updateBlogPost }}>
      {children}
    </BlogContext.Provider>
  );
};

BlogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogContext;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "@/context/useAuth";
import PropTypes from "prop-types";

const ProtectedRoute = ({ requiredRole, children }) => {
  const { user, role } = useAuth();

  // Check if user is authenticated and has the required role
  if (!user) {
    console.log("User not logged in. Redirecting to login.");
    return <Navigate to="/login" replace />;
  }

  if (requiredRole && role !== requiredRole) {
    console.log(`User role '${role || 'undefined'}' does not match required role '${requiredRole}'. Redirecting.`);
    return <Navigate to="/" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  requiredRole: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;

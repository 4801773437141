// src/components/CustomButton.jsx
import React from 'react';
import '@/styles/CustomButton.css'; // Import the CSS for your button

const CustomButton = ({ children, className, href, ...props }) => {
    if (href) {
        return (
            <a className={`my-custom-btn ${className}`} href={href} {...props}>
                {children}
            </a>
        );
    }
    return (
        <button className={`my-custom-btn ${className}`} {...props}>
            {children}
        </button>
    );
};

export default CustomButton;

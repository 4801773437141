// src/components/Header.js
import React, { useState, useEffect } from 'react';
import '@/styles/Header.css';
import logo from '@/assets/images/logo.png';

function Header() {
  const [consoleText, setConsoleText] = useState([]);
  
  useEffect(() => {
    const commands = [
      "admin@hax:~$ ./Hello.sh",
      "Welcome to IncredInComp.com",
      "Enjoy your stay...",
      "Stay curious, my friends!",
      "admin@hax:~$"
    ];
    let i = 0;
    let timeoutId;
  
    const printCommand = () => {
      if (i < commands.length) {
        setConsoleText((prevText) => [...prevText, commands[i++]]);
        timeoutId = setTimeout(printCommand, 1500);
      }
    };
  
    printCommand();
  
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <header className="header text-white">
      <div className="container-fluid p-3">
        <div className="row align-items-center header-back d-flex text-center flex-column flex-md-row">
          <div className="col-md-6 logo-container mb-3 mb-md-0">
            <img src={logo} alt="IncredInComp Logo" className="img-fluid rounded-circle logo" />
          </div>
          <div className="col-md-6 console-container">
            <div className="shadow-lg rounded bg-dark p-2">
              <div className="console-header mb-2 d-flex align-items-center">
                <div className="console-buttons d-flex">
                  <div className="console-button bg-danger me-2"></div>
                  <div className="console-button bg-warning me-2"></div>
                  <div className="console-button bg-success"></div>
                </div>
              </div>
              <pre className="text-white console-text">
                {consoleText.map((text, index) => (
                  <div key={index}>{text}</div>
                ))}
                <span className="prompt-text">admin@hax:~$</span> <span className="blinking">▌</span>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

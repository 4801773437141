import React, { useState } from 'react';
import '@/styles/Footer.css';
import bskyFavicon from '@/assets/images/bsky-favicon.png';
import CustomButton from '@/components/CustomButton';
// import { subscribeToNewsletter, sendVerificationEmail } from '@/services/NewsletterSubscription';

function Footer() {
/*     const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubscription = async (e) => {
        e.preventDefault();
        try {
            const data = await subscribeToNewsletter(email);
            await sendVerificationEmail(email); // Send verification email
            setMessage('Subscription successful! Please check your email to verify your subscription.');
        } catch (error) {
            setMessage('Subscription failed. Please try again.');
        }
    }; */

    return (
        <footer className="footer">
            <div className="container-fluid p-4 text-white">
                {/* Things I Support Section */}
                <div className="row mb-3">
                    <div className="col text-center">
                        <h4>Things I Support</h4>

                        {/* Carousel for Mobile Devices */}
                        <div
                            id="supportCarousel"
                            className="carousel slide d-md-none"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <CustomButton
                                        className="btn-custom"
                                        href="https://www.eff.org/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="The Electronic Frontier Foundation: Defending civil liberties in the digital world."
                                        type="button" /* Ensure it's not treated as a submit button */
                                    >
                                        EFF.org
                                    </CustomButton>
                                </div>
                                <div className="carousel-item">
                                    <CustomButton
                                        className="btn-custom"
                                        href="https://www.aclu.org/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Protecting individual rights and liberties."
                                        type="button" /* Ensure it's not treated as a submit button */
                                    >
                                        ACLU.org
                                    </CustomButton>
                                </div>
                                <div className="carousel-item">
                                    <CustomButton
                                        className="btn-custom"
                                        href="https://www.raspberrypi.org/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Promoting the study of basic computer science."
                                        type="button" /* Ensure it's not treated as a submit button */
                                    >
                                        RaspberryPi.org
                                    </CustomButton>
                                </div>
                            </div>
                            <a
                                className="carousel-control-prev"
                                href="#supportCarousel"
                                role="button"
                                data-bs-slide="prev"
                            >
                                <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Previous</span>
                            </a>
                            <a
                                className="carousel-control-next"
                                href="#supportCarousel"
                                role="button"
                                data-bs-slide="next"
                            >
                                <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Next</span>
                            </a>
                        </div>

                        {/* Grid for Larger Devices */}
                        <div className="d-none d-md-block">
                            <div className="d-grid gap-2">
                                <CustomButton
                                    className="btn-custom"
                                    href="https://www.eff.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Defending civil liberties in the digital world."
                                    type="button" /* Ensure it's not treated as a submit button */
                                >
                                    The Electronic Frontier Foundation
                                </CustomButton>
                                <CustomButton
                                    className="btn-custom"
                                    href="https://www.aclu.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Protecting individual rights and liberties."
                                    type="button" /* Ensure it's not treated as a submit button */
                                >
                                    ACLU.org
                                </CustomButton>
                                <CustomButton
                                    className="btn-custom"
                                    href="https://www.raspberrypi.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Promoting the study of basic computer science."
                                    type="button" /* Ensure it's not treated as a submit button */
                                >
                                    RaspberryPi.org
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Quick Links Section */}
                <div className="row mb-3">
                    <div className="col text-center">
                        <h4>Quick Links</h4>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                            <CustomButton href="/whoami" className="btn-custom" type="button">whoami</CustomButton>
                            <CustomButton href="/contact" className="btn-custom" type="button">Contact</CustomButton>
                            <CustomButton href="/faq" className="btn-custom" type="button">FAQ</CustomButton>
                        </div>
                    </div>
                </div>

                {/* Newsletter Subscription Section for the future */}
{/*                 <div className="row mb-3">
                    <div className="col text-center">
                        <h4>Subscribe to our Newsletter</h4>
                        <form className="d-flex justify-content-center" onSubmit={handleSubscription}>
                            <input
                                type="email"
                                className="form-control me-2"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <CustomButton type="submit" className="btn-custom" > Subscribe </CustomButton>
                        </form>
                        {message && <p>{message}</p>}
                    </div>
                </div> */}

                {/* "Thanks for Stopping By" Section */}
                <div className="row">
                    <div className="col text-center text-light bg-transparent p-2 rounded">
                        <h3>Thanks for stopping by!</h3>
                        <p>
                            My name is <strong>IncredInComp</strong>.
                            <br />
                            Stay Curious, my friends.
                        </p>
                        <div className="text-center mb-3 bsky-brand">
                            <a
                                href="https://bsky.app/profile/incredincomp.com"
                                className="btn-custom inverted-bsky-button d-flex align-items-center justify-content-center"
                            >
                                <img
                                    src={bskyFavicon}
                                    alt="Follow on Bluesky"
                                    className="bsky-brand-img"
                                />
                                <span>Follow me on Bluesky</span>
                            </a>
                        </div>
                        <p>&copy; 2024 Some rights reserved.</p>
                        <a href="/privacy" className="text-white">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

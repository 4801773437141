import React, { createContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../components/firebase";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("user"); // Default role is 'user'

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        if (currentUser) {
          const token = await currentUser.getIdTokenResult();
          console.log("Custom Claims:", token.claims); // Debugging
          setUser(currentUser);
          setRole(token.claims.role || "user"); // Default to 'user' if no role is provided
        } else {
          setUser(null);
          setRole("user");
        }
      } catch (error) {
        console.error("Error during authentication state change:", error.message);
        setUser(null);
        setRole("user");
      }
    });
  
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error during logout:", error.message);
    } finally {
      setUser(null);
      setRole("user");
    }
  };

  // Include setUser and setRole in the context
  const value = useMemo(() => ({ user, role, logout, setUser, setRole }), [
    user,
    role,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
